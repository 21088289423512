@mixin phone {
    @media (max-width: 599px) { @content; }
}

.play-pause-icon {
    height: 65px;
    width: 65px;
    color: black;
    border-style: solid;
    border-radius: "100px";
}

.player {
    width: 200px;
    height: 200px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    // @include phone {
    //     transform: scale(70%);
    // }
}

.active {
    filter: drop-shadow(0 0 3px);
}

.play-pause-caption {
    position:fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 30px;
}

.align-verically {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.hover {
    padding-bottom: 15px;
    // transform: scale(1.1);
    transition: padding-bottom .3s;
}

.un-hover {
    padding-bottom: 0px;
    // transform: scale(1.1);
    transition: padding-bottom .3s;
}