@mixin phone {
    @media (max-width: 599px) { @content; }
}

@mixin biggish {
    @media (min-width: 1200px) { @content; }
}

@mixin huge {
    @media (min-width: 1800px) { @content; }
}

$margin-top-artists: 40px;

a {
    text-decoration: none;
    color: #1976d2 !important;
}

.bg {
    background-image: url("./bin/img/galki.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    background-size: cover;
    @include phone {
        background-size: auto calc(110 * var(--vh));
    }
}

.bg-solid {
    background-color: #FFF;
}

.hide-on-phone {
    @include phone {
        display: none
    }
}

.logo {
    height: 70vh;
    width: auto;
    max-width: 90vw;
    filter: drop-shadow(-1px 3px 5px #000); // blur(0.4px);
}

.plugin {
    filter: drop-shadow(0px 0px 3px);
}

.page {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    @include phone {
        align-content: flex-start;
        align-items: flex-start;
    }
}

.profpic {
    mix-blend-mode: lighten;
    filter: blur(1.5px) contrast(0.9);
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1300px;
    left:auto;
    right:auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    @include biggish {
        max-width: 1200px;
    }
    @include phone {
        padding: #{$margin-top-artists} 0px 0px 0px;
        align-content: flex-start;
        align-items: flex-start;
        min-width: 300px;
    }
}

.swiper-button-prev, .swiper-button-next {
    @include phone {
        top: calc(50vw + #{$margin-top-artists}) !important;
        color: white !important;
        background-color: rgba(25, 25, 25, 0.05);
    }
}
.no-padding-on-mobile {
    @include phone {
        padding: 0px !important;
    }
}
.card {
    width: 50%;
    height: auto;
    min-width: 400px;
    @include phone {
        width: 100%;
        min-width: 300px;
    }
}

.card-content {
    padding: 10px;
    @include phone {
        padding: 7px;
    }
    @include biggish {
        padding: 25px;
    }
    @include huge {
        padding: 45px;
    }
}

.square-image {
    width: 100%;
    height: auto;
}

// S O C I A L S
.contact {
    padding: 40px 0px 0px 0px;
    align-content: center !important;
    align-items: center !important;
}

.contact-info {
    filter: drop-shadow(0 0 12px #000);
    color: white;
    @include phone {
         padding: 3px !important;
         text-align: center;
    } 
}

.social-icon {
    background-color: #FFF;
    border-radius: 4px;
    mix-blend-mode: overlay;
    max-width: 9vw;
    max-height: 9vw;
    min-width: 30px;
    min-height: 25px;
}

.s-title, .s-icons {
    padding: 10px;
    @include phone {
        padding: 10px;
   } 
}

.MuiTypography-h3 {
    @include phone {
        padding: 15px 0 5px 0;
    } 
}

.s-title {
    filter: drop-shadow(0.5px 0px 0.5px #FFF);
}
.a-social-icon {
    margin: 3px 25px 3px 3px;
    :hover {
        filter: 
            drop-shadow(0px 0px 40px #FFF)
            drop-shadow(0px 0px 6px #FFF);
    }
    @include phone {
        margin: 10px;
   } 
}
