@mixin phone {
    @media (max-width: 599px) { @content; }
}

.motto {
    padding-bottom: 100px;
    @include phone {
        padding-bottom: 15px;
    }
}

.players-wrapper {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}